<template>
  <div class="modal-save-filter">
    <div class="modal-save-filter-container">
      <img
        src="../../../assets/images/icons/close_icon.svg"
        alt="Modal close icon"
        v-on:click="closeModal()"
      />

      <h2>Save filter</h2>
      <p>
        Save your filter for the next time, you can save multiple search
        filters.
      </p>

      <TextInput
        inputLabel="Name your filter"
        inputName="name"
        inputType="text"
        inputClass="dashboard"
        v-model="filterName"
      />

      <CheckboxButton
        ref="recieveNotification"
        inputName="recieveEmail"
        inputLabel="Email me about new listings based on search"
        v-model="recieveNotification"
      />

      <div class="modal-save-filter-container-filters">
        <p>Your saved filter will contain:</p>
        <ul>
          <li v-for="(filter, index) in filters" :key="index">{{ filter }}</li>
        </ul>
      </div>

      <div class="modal-save-filter-container-buttons">
        <MercalaButton
          v-on:button-event="closeModal()"
          label="Cancel"
          type="cancel"
        />

        <MercalaButton
          v-on:button-event="saveFilter()"
          label="Save"
          type="ok"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";
import TextInput from "@/components/form/TextInput";
import MercalaButton from "@/components/form/MercalaButton";
import CheckboxButton from "@/components/form/CheckboxButton";
import AngelaAPI from "@/utils/AngelaAPI";
import { EventBus } from "@/utils/EventBus.js";
export default {
  name: "SaveFilterModal",
  components: {
    TextInput,
    MercalaButton,
    CheckboxButton,
  },
  data() {
    return {
      filterName: "",
      recieveNotification: false,
      filters: [],
      appliedFilters: {
        country: "",
        districts: "",
        bedrooms: "",
        bathrooms: "",
        reduced: "",
        daysListed: "",
        minPrice: "",
        maxPrice: "",
        pool: "",
        parcel_type: "",
        lotSize: {
          min: "",
          max: "",
        },
      },
    };
  },
  mounted() {
    this.appliedFilters = this.$store.getters.getFormValues("appliedFilters");
    this.generateFilterItems();
  },
  methods: {
    setAppliedFilters(appliedFilters) {
      this.appliedFilters = appliedFilters;
    },
    generateFilterItems() {
      this.filters = Helpers.filterTagGenerator(this.appliedFilters);
    },
    saveFilter() {
      AngelaAPI.createFilter({
        name: this.filterName,
        query: Helpers.saveSearchPayloadCreater(this.appliedFilters),
        recieveNotification: this.recieveNotification,
      })
        .then((response) => {
          this.$emit("close-modal");
          EventBus.$emit("refresh-saved-filters", response.data.data.id);
          EventBus.$emit("show-notification", {
            title: "Success!",
            message: "Filter saved successfuly!",
            success: true,
            milliseconds: 3000,
          });
        })
        .catch((_error) => {
          // Noting to catch, all errors should be handled on the front end
          // console.log(error.response);
          EventBus.$emit("show-notification", {
            title: "Oh no...",
            message: "Something went wrong, try again later",
            success: false,
            milliseconds: 3000,
          });
        });
    },
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss">
.modal-save-filter {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);

  &-container {
    width: 530px;
    margin: 110px auto;
    background-color: white;
    padding: 35px;
    border-radius: 15px;
    position: relative;

    img {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    p {
      margin-top: 10px;
      font-size: 1rem;
      line-height: 1.5625rem;
    }

    .form-text-input {
      margin-top: 25px;
    }

    .form-checkbox-button {
      margin-top: 20px;

      img {
        top: 0px;
      }
    }

    &-filters {
      margin-top: 25px;
      ul {
        margin-top: 15px;
        li {
          display: inline-block;
          border-radius: 8px;
          font-size: 0.75rem;
          width: fit-content;
          background: rgb(255, 30, 30);
          color: white;
          margin-bottom: 6px;
          margin-right: 6px;
          padding: 8px 10px;
        }
      }
    }

    &-buttons {
      margin-top: 25px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
